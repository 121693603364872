import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Main from 'components/Main';
import MenuButton from 'components/MenuButton';
import { NavigateToDatasetButton } from 'components/NavigateToDatasetButton';
import PageHeader from 'components/PageHeader';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { buildUserContext, evaluateFlag } from 'feature_flags/FeatureFlags';
import usePagination from 'hooks/usePagination';
import {
  getDynamicTagsAsTableQuery,
  useDeleteCategoryMutation,
  useGetCategoryById,
} from 'queries/dynamic-tags';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PaginationState } from 'types/pagination';
import { toast } from 'react-toastify';
import { EmptyErrorState } from '../components/ErrorEmptyState';
import { DynamicTagTableView } from './components/DynamicTagTableView';
import DeleteCategoryModal from '../components/DeleteCategoryModal';

const DynamicTagCategoryPage: React.FunctionComponent<{}> =
  function DynamicTagCategoryPage() {
    const { categoryId } = useParams();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const { data: category, isError, refetch } = useGetCategoryById(categoryId);
    const { user: auth0User } = useAuth0();
    const userContext = buildUserContext(auth0User?.sub);
    const isDeleteDynamicTagsEnabled = evaluateFlag(
      FeatureFlag.DELETE_DYNAMIC_TAGS_WIP,
      userContext,
    );
    const { mutate: deleteCategory } = useDeleteCategoryMutation();

    const pagination = usePagination();
    const navigate = useNavigate();
    const {
      data: dynamicTags,
      isLoading,
      isFetched,
    } = getDynamicTagsAsTableQuery({
      categoryId: categoryId || '',
      limit: pagination.size,
      offset: (pagination.page - 1) * pagination.size,
    });

    const paginationState = useMemo<PaginationState>(
      () => ({ page: pagination.page, size: pagination.size }),
      [pagination.page, pagination.size],
    );
    const loadingTable = useMemo(() => <LoadingTable />, []);
    const loading = isLoading && !isFetched;

    // handle case when prompt object is null
    const parsedResponseData = dynamicTags
      ? dynamicTags.data.map((tableRow) => ({
          ...tableRow,
          data: {
            ...tableRow.data,
            textPrompts: tableRow.data.textPrompts
              ? tableRow.data.textPrompts.map((prompt) => prompt.text)
              : [],
            visualPrompts: tableRow.data.visualPrompts || [],
          },
        }))
      : [];

    const breadcrumbs = [
      {
        label: 'Categories',
        to: '/dynamic-tag-categories',
      },
      {
        label: `${category?.name || 'Category'}: Dynamic tags`,
        to: '',
      },
    ];

    const title = (
      <div className="flex">
        <h1 data-cy="category-page-title">{category?.name || 'Category'}</h1>
        <div className="mx-3">
          <NavigateToDatasetButton
            // TODO: fix to handle multiple datasets
            datasetName={category?.datasets[0].name || ''}
            path={`/datasets/${category?.datasets[0].id}`}
          />
        </div>
      </div>
    );

    const handleDeleteCategory = async () => {
      setIsDeleteModalOpen(false);

      try {
        await new Promise<void>((resolve, reject) => {
          deleteCategory(
            { categoryId: category?.categoryId! },
            {
              onSuccess: () => {
                navigate('/dynamic-tag-categories');
                toast.success('Done! Your category is being deleted.');
                resolve();
              },
              onError: () => {
                toast.error(
                  'Something went wrong while deleting your category. Please try again.',
                );
                reject();
              },
            },
          );
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    return (
      <Main loading={!category?.name || isLoading}>
        <DeleteCategoryModal
          dataTestId="category-page-delete-modal"
          onClose={setIsDeleteModalOpen}
          onConfirm={handleDeleteCategory}
          shouldDisplay={isDeleteModalOpen}
          categoryToDelete={category!}
        />
        <div className="max-w-8xl mx-auto">
          <PageHeader
            title={title}
            breadcrumbs={breadcrumbs}
            backButtonTo=""
            actions={
              <div className="space-x-2">
                {isDeleteDynamicTagsEnabled && (
                  <MenuButton
                    position="right"
                    label="More"
                    items={[
                      [
                        {
                          dataTestId: 'delete-category-btn',
                          id: 'delete-category',
                          name: (
                            <span className="text-red-500">
                              Delete category
                            </span>
                          ),
                          onClick: () => setIsDeleteModalOpen(true),
                        },
                      ],
                    ]}
                    dataTestId="category-page-more-btn"
                  />
                )}
                <Button
                  dataTestId="category-page-create-dynamic-tag-button"
                  onClick={() => navigate('dynamic-tag/add')}
                >
                  Add dynamic tag
                </Button>
              </div>
            }
          />
          {isError ? (
            <div className="h-[80vh] flex items-center justify-center">
              <EmptyErrorState itemWithError="dynamic tags" />
            </div>
          ) : (
            <div className="pt-8">
              <FadeTransition show={Boolean(!loading && dynamicTags)} appear>
                {dynamicTags && (
                  <DynamicTagTableView
                    refetchDynamicTags={refetch}
                    dynamicTags={parsedResponseData}
                    pagination={pagination}
                    total={dynamicTags.meta.page.total || 0}
                    columns={dynamicTags.columns}
                    isLoading={isLoading}
                    paginationState={paginationState}
                  />
                )}
              </FadeTransition>
              <FadeTransition
                show={loading}
                appear
                enterDelayMs={500}
                exit={false}
              >
                {loading && loadingTable}
              </FadeTransition>
            </div>
          )}
        </div>
      </Main>
    );
  };

export default DynamicTagCategoryPage;
