import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { FeatureFlag } from './FeatureFlagConfig';

// These methods should be used to interface with launchdarkly's react SDK
// The LDProvider is instantiated in the root index file
// Docs: https://docs.launchdarkly.com/sdk/client-side/react/react-web#overview

const userContextPrefix = 'user-context-';
const orgContextPrefix = 'org-context-';

const toCamelCase = (str: string): string =>
  str
    .replace(/([-_][a-z])/gi, (match) =>
      match.toUpperCase().replace('-', '').replace('_', ''),
    )
    .replace(/[^a-zA-Z0-9]/g, '');

export const buildUserContext = (userId?: string, userProps: object = {}) => {
  // Creates a user context from passed in props.
  // If we are targeting a specific user, use this method to build the context.

  if (!userId) {
    throw new Error('userId is required to build user context');
  }
  const defaultContext = {
    kind: 'user',
    key: userContextPrefix + userId,
  };
  const userContext = { ...defaultContext, ...userProps };
  return userContext;
};

export const buildOrgContext = (orgId: string, orgProps: object = {}) => {
  // Creates an org context from passed in props.
  // If we are targeting a specific org, use this method to build the context.

  if (!orgId) {
    throw new Error('orgId is required to build user context');
  }
  const defaultContext = {
    kind: 'organization',
    key: orgContextPrefix + orgId,
  };
  const orgContext = { ...defaultContext, ...orgProps };
  return orgContext;
};

export const evaluateFlag = (flagName: FeatureFlag, context: object) => {
  // flag keys must be validated to camelCase...beware collisions.
  // Evaluate flags that have been configured as boolean, string, number, or json flag types.

  const validatedFlagName = toCamelCase(flagName);
  const flags = useFlags();
  const ldClient = useLDClient();

  if (ldClient) {
    ldClient.identify(context);
  } else {
    throw new Error('launch darkly client is not available');
  }

  const flagValue = flags[validatedFlagName];

  return flagValue;
};
