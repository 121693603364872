import {
  ConceptApi,
  DatasetApi,
  DatasetStatusEnum,
  DeleteConceptRequest,
  LabelApi,
  LabelEnum,
  UpdateConceptByIdRequest,
  VersionApi,
} from 'api/generated';
import { useAPIContext } from 'context/APIContext';
import useInfiniteQuery from 'hooks/useInfiniteQuery';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { useEffect, useState } from 'react';
import toNumber from 'utils/ToNumber';

const EXAMPLES_PAGE_SIZE = 50;
const CHECK_CAN_GET_CANDIDATES_INTERVAL = 2000; // 2 seconds

export const useGetVersionQuery = (conceptVersionId: string) => {
  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getVersionApiVersionsVersionIdGet', conceptVersionId],
    async ({ queryKey, signal }) => {
      const [, _conceptVersionId] = queryKey;
      if (_conceptVersionId === 'latest') {
        throw new Error('Invalid usage of get versions api');
      }
      const api = await initializeAPI<VersionApi>(VersionApi);
      return api.getVersionById(
        {
          versionId: _conceptVersionId,
        },
        { signal },
      );
    },
    { enabled: !!refetchInterval, refetchInterval },
  );
  useEffect(() => {
    if (query.data?.jobStatus === 'Running') {
      setRefetchInterval(CHECK_CAN_GET_CANDIDATES_INTERVAL);
    } else {
      setRefetchInterval(false);
    }
  }, [query.data?.jobStatus]);
  return query;
};

export const useGetLatestConceptVersionQueryKey = (conceptId: string) => [
  'getLatestConceptVersionById',
  conceptId,
];

export const useGetLatestConceptVersionQuery = (conceptId: string) => {
  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    useGetLatestConceptVersionQueryKey(conceptId),
    async ({ queryKey, signal }) => {
      const [, _conceptId] = queryKey;

      const api = await initializeAPI<VersionApi>(VersionApi);
      return api.getLatestConceptVersionById(
        {
          conceptId: _conceptId,
        },
        { signal },
      );
    },
    { enabled: !!refetchInterval, refetchInterval },
  );
  useEffect(() => {
    if (query.data?.jobStatus === 'Running') {
      setRefetchInterval(CHECK_CAN_GET_CANDIDATES_INTERVAL);
    } else {
      setRefetchInterval(false);
    }
  }, [query.data?.jobStatus]);
  return query;
};

export const getConceptQueryKey = (conceptId: string) => [
  'getConceptByIdApiConceptsConceptIdGet',
  conceptId,
];

export const useGetConceptQuery = (conceptId: string) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getConceptQueryKey(conceptId),
    async ({ queryKey, signal }) => {
      const [, _conceptId] = queryKey;
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.getConcept(
        {
          conceptId: _conceptId.toString(),
        },
        { signal },
      );
    },
    { enabled: false },
  );
  return query;
};

export const useGetLabelsForVersionInfiniteQueryKey = (
  conceptVersionId: string,
  labelsToInclude?: LabelEnum[],
) =>
  labelsToInclude?.length
    ? ['getConceptVersionLabels', conceptVersionId, labelsToInclude]
    : ['getConceptVersionLabels', conceptVersionId];

export const useGetLabelsForVersionInfiniteQuery = (
  conceptVersionId: string,
  labelsToInclude?: LabelEnum[],
  pageSize: number = EXAMPLES_PAGE_SIZE,
  enabled: boolean = true,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useInfiniteQuery(
    useGetLabelsForVersionInfiniteQueryKey(conceptVersionId, labelsToInclude),
    async ({ queryKey, signal, pageParam }) => {
      const [, _conceptVersionId] = queryKey;
      if (typeof _conceptVersionId === 'undefined') {
        throw new Error('conceptVersionId not set');
      }
      const api = await initializeAPI<LabelApi>(LabelApi);

      const requestParams = {
        conceptVersionId: _conceptVersionId.toString(),
        offset: pageSize * toNumber(pageParam || 0),
        limit: pageSize,
        // TODO: When labelsToInclude is empty, FastApi raises 422 Unprocessable Entity exception
        labelsToInclude: labelsToInclude?.length
          ? labelsToInclude
          : [LabelEnum._1, LabelEnum._0],
      };
      return api.getConceptVersionLabels(requestParams, { signal });
    },
    {
      enabled,
      keepPreviousData: false,
      getNextPageParam: (lastPage) => lastPage.meta?.page?.currentPage,
    },
  );
  return { ...query, isLoading: query.isLoading };
};

export const useDeleteConceptMutation = (conceptId: string) => {
  const { initializeAPI } = useAPIContext();

  return useMutation(
    ['deleteConcept', conceptId],
    async (variables: DeleteConceptRequest) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.deleteConcept(variables);
    },
  );
};

export const updateConceptMutation = (conceptId: string) => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    ['updateConceptByIdApiConceptsConceptIdPatch', conceptId],
    async (variables: UpdateConceptByIdRequest) => {
      const api = await initializeAPI<ConceptApi>(ConceptApi);
      return api.updateConceptById(variables);
    },
  );
};

export const getDatasetsQueryKey = (
  statusesToInclude: DatasetStatusEnum[] | undefined = undefined,
) => ['getDatasets', ...(statusesToInclude ?? [])];

export const useGetDatasetsQuery = (
  statusesToInclude: DatasetStatusEnum[] | undefined = undefined,
) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    getDatasetsQueryKey(statusesToInclude),
    async ({ signal }) => {
      const api = await initializeAPI<DatasetApi>(DatasetApi);
      return api.getDatasets({ statusesToInclude }, { signal });
    },
  );
  return query;
};

export const useExportConceptVersionAsCsv = (conceptVersionId: string) => {
  const { initializeAPI } = useAPIContext();
  const query = useQuery(
    ['getVersionCsv', conceptVersionId],
    async ({ signal }) => {
      const api = await initializeAPI<VersionApi>(VersionApi);
      return api.getVersionCsv({ versionId: conceptVersionId }, { signal });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );
  return query;
};
