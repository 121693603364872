import { useAuth0 } from '@auth0/auth0-react';
import {
  Column,
  ColumnDataType,
  ColumnDisplayType,
  DynamicTagUI,
} from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import FadeTransition from 'components/FadeTransition';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { buildUserContext, evaluateFlag } from 'feature_flags/FeatureFlags';
import { PaginationHook } from 'hooks/usePagination';
import DeleteDynamicTagModal from 'pages/dynamic-tags/components/DeleteDynamicTagModal';
import { useDeleteDynamicTagMutation } from 'queries/dynamic-tags';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationState } from 'types/pagination';
import { AppendedCellContent } from 'types/table';

interface DynamicTagTableViewProps {
  columns: Column[];
  // TODO: fix typing
  dynamicTags: any[];
  pagination: PaginationHook;
  paginationState: PaginationState;
  isLoading: boolean;
  total: number;
  refetchDynamicTags?: () => void;
}

const COLUMNS = [
  {
    key: 'name',
    label: 'Dynamic tags',
    to: '/dynamic-tag-categories/{categoryId}/dynamic-tag/{dynamicTagId}',
    displayType: ColumnDisplayType.Action,
  },
  {
    key: 'textPrompts',
    label: 'Text prompts',
    displayType: ColumnDisplayType.BadgeList,
  },
  {
    key: 'visualPrompts',
    label: 'Visual prompts',
    displayType: ColumnDisplayType.ImageSetPreview,
  },
  {
    key: 'updatedDt',
    label: 'Last updated',
    displayType: ColumnDisplayType.RelativeTimestamp,
    dataType: ColumnDataType.Date,
  },
];

export const DynamicTagTableView: React.FunctionComponent<DynamicTagTableViewProps> =
  function DynamicTagTableView({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    columns,
    dynamicTags,
    pagination,
    paginationState,
    isLoading,
    total,
    refetchDynamicTags,
  }) {
    const [appendedCellContent, setAppendedCellContent] =
      useState<AppendedCellContent>(null);
    const { categoryId } = useParams();
    const loadingTable = useMemo(() => <LoadingTable />, []);
    const { user: auth0User } = useAuth0();
    const userContext = buildUserContext(auth0User?.sub);
    const isDeleteDynamicTagsEnabled = evaluateFlag(
      FeatureFlag.DELETE_DYNAMIC_TAGS_WIP,
      userContext,
    );
    const { mutate: deleteDynamicTag } = useDeleteDynamicTagMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [dynamicTagToDelete, setDynamicTagToDelete] =
      useState<DynamicTagUI | null>(null);

    const handleOpenDeleteModal = (rowData: DynamicTagUI) => {
      setDynamicTagToDelete(rowData);
      setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = (isOpen: boolean) => {
      setDynamicTagToDelete(null);
      // HeadlessDialog component inside the modal requires this to be the boolean parameter instead of false in this case
      setIsDeleteModalOpen(isOpen);
    };

    const handleDeleteDynamicTag = async () => {
      setIsDeleteModalOpen(false);
      setAppendedCellContent({
        cellIds: [`${dynamicTagToDelete?.dynamicTagId}-name`],
        text: 'Deleting...',
        className: 'ml-2 text-yellow-500',
      });
      try {
        await new Promise<void>((resolve, reject) => {
          deleteDynamicTag(
            {
              categoryId: categoryId!,
              dynamicTagId: dynamicTagToDelete?.dynamicTagId!,
            },
            {
              onSuccess: () => {
                toast.success('Done! Your dynamic tag is being deleted.');
                resolve();
              },
              onError: () => {
                toast.error(
                  'Something went wrong while deleting your dynamic tag. Please try again.',
                );
                reject();
              },
            },
          );
        });

        refetchDynamicTags?.();
        setDynamicTagToDelete(null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error while deleting dynamic tag:', error);
      } finally {
        setAppendedCellContent(null);
      }
    };

    return (
      <>
        <DeleteDynamicTagModal
          dataTestId="dynamic-tags-table-delete-modal"
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteDynamicTag}
          shouldDisplay={isDeleteModalOpen}
          dynamicTagToDelete={dynamicTagToDelete!}
        />
        <FadeTransition show={Boolean(!isLoading && dynamicTags)} appear>
          <Table
            dataTestId="dynamic-tags"
            appendedCellContent={appendedCellContent}
            columns={COLUMNS}
            data={dynamicTags as any}
            idKey="dynamicTagId"
            actions={
              isDeleteDynamicTagsEnabled
                ? [
                    {
                      dataTestId: 'delete-dynamic-tag-btn',
                      key: 'delete',
                      label: (
                        <span className="text-red-500">Delete dynamic tag</span>
                      ),
                      action: (row) => handleOpenDeleteModal(row.data),
                    },
                  ]
                : undefined
            }
            total={total}
            emptyMessage={
              <div className="text-center py-12 px-2 text-sm">
                <EmptyStateMessage>
                  <p className="pb-4">No dynamic tags to show</p>
                </EmptyStateMessage>
                <Link
                  to="dynamic-tag/add"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Create a dynamic tag
                </Link>
              </div>
            }
            loadStrategy="paginated"
            activePage={paginationState.page}
            pageSize={paginationState.size}
            setPageSize={pagination.setSize}
            loadPage={pagination.setPage}
          />
        </FadeTransition>
        <FadeTransition
          show={Boolean(isLoading)}
          appear
          enterDelayMs={500}
          exit={false}
        >
          {!!isLoading && loadingTable}
        </FadeTransition>
      </>
    );
  };

DynamicTagTableView.defaultProps = {
  refetchDynamicTags: undefined,
};
