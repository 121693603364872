import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import TextInput from 'components/TextInput';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { buildUserContext, evaluateFlag } from 'feature_flags/FeatureFlags';
import Page403 from 'pages/Page403';
import Page from 'pages/datasets/components/Page';
import { useCreateDynamicTagTableMutation } from 'queries/datasets';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const TABLE_NAME_INPUT_PLACEHOLDER =
  'Enter a table suffix for these dynamic tags so you can run a query on them';
const TEXT_AREA_INPUT_PLACEHOLDER =
  'Enter each dynamic tag on a new line. For example:\nsoccer\nbasketball\nbaseball\ncricket\nrugby';
// Keep prefix in sync with coactive_platform/core/coactive_core/constants.py:DYNAMIC_TAGS_PREFIX
const TABLE_NAME_PREFIX = 'dynamic_tags_';

/**
 * The top-level component for the add dynamic tags flow.
 */

const AddDynamicTags = function AddDynamicTags() {
  const { datasetId } = useParams();
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();

  const userContext = buildUserContext(auth0User?.sub);
  const isDynamicTaggingEnabled = evaluateFlag(
    FeatureFlag.ENABLE_DYNAMIC_TAGGING,
    userContext,
  );

  if (!isDynamicTaggingEnabled) {
    return <Page403 />;
  }

  const [isMakingRequest, setIsMakingRequest] = useState<boolean>(false);
  const [tableName, setTableName] = useState<string>();
  const [dynamicTagsRawText, setDynamicTagsRawText] = useState<string>();

  const { mutate: createDynamicTagTable } = useCreateDynamicTagTableMutation(
    datasetId || '',
  );
  const createDynamicTagTableFn = async (
    tableNameSuffix: string,
    dynamicTags: Array<string>,
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!datasetId) {
        reject();
      }
      setIsMakingRequest(true);
      createDynamicTagTable(
        {
          // @ts-ignore: datasetId should be defined at this point in execution
          datasetId,
          createDynamicTagTableRequest: {
            tableNameSuffix,
            dynamicTags,
          },
        },
        {
          onSuccess: () => {
            setIsMakingRequest(false);
            navigate(`/datasets/${datasetId}`);
            toast.success('Success! Your dynamic tags table will be added!');
          },
          onError: () => {
            setIsMakingRequest(false);
            toast.error('Dynamic tags could not be created. Please try again.');
          },
        },
      );
    });

  const onAddTags = () => {
    // TextInput returns the table name including the prefix, so remove it
    const tableNameSuffix = tableName?.startsWith(TABLE_NAME_PREFIX)
      ? tableName.split(TABLE_NAME_PREFIX)[1]
      : tableName;
    const dynamicTags =
      dynamicTagsRawText?.split('\n').map((text) => text.trim()) || [];

    if (!tableNameSuffix || !dynamicTags.length) {
      // TODO: better error handling
      return;
    }
    createDynamicTagTableFn(tableNameSuffix, dynamicTags);
  };

  return (
    // TODO: add submit button to top of page
    // TODO: handle table name validation on the front-end
    <Page title="Add dynamic tags">
      <div className="ml-4 mr-4">
        <div className="mt-12 mb-8">
          <h1 className="text-xl font-bold">Add dynamic tags</h1>
          <span>
            You can use dynamic tags for image and video analysis. For each tag,
            Coactive will compute the similarity score which you can run queries
            on in the Queries tab.
          </span>
        </div>
        <div className="mb-8">
          <TextInput
            id="table-suffix"
            name="table-suffix"
            label="Dynamic tags table name"
            prefix={TABLE_NAME_PREFIX}
            placeholder={TABLE_NAME_INPUT_PLACEHOLDER}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTableName(e.target.value)
            }
          />
        </div>
        <TextArea
          id="dynamic-tags-raw"
          name="dynamic-tags-raw"
          label="Dynamic tags"
          className="h-72 mb-12"
          placeholder={TEXT_AREA_INPUT_PLACEHOLDER}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setDynamicTagsRawText(e.target.value)
          }
        />
        <div className="border-t w-full text-right pt-4 pb-4">
          <Button
            onClick={onAddTags}
            rightIcon={ArrowRightIcon}
            disabled={isMakingRequest || !(tableName && dynamicTagsRawText)}
          >
            Add dynamic tags
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default AddDynamicTags;
